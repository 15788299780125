<template>
  <div class="d-flex mr-4">
    <ul class="list-inline propFilter-top" v-if="filterType !== 'lesiurTab'">
      <li :class="['list-inline-item', priceLTH ? 'active':'']" v-if="buttonList.indexOf('priceLTH') > -1">
        <button
          type="checkbox"
          class="custom-control-input"
          id="priceLTH"
          @click="setKindSort('priceLTH')"
        />
        <label for="priceLTH">
          <span>{{ $t('search-result.priceLTH') }}</span>
        </label>
      </li>
      <li :class="['list-inline-item', priceHTL ? 'active':'']" v-if="buttonList.indexOf('priceHTL') > -1">
        <button
          type="checkbox"
          class="custom-control-input"
          id="priceHTL"
          @click="setKindSort('priceHTL')"
        />
        <label for="priceHTL">
          <span>{{ $t('search-result.priceHTL') }}</span>
        </label>
      </li>
      <li :class="['list-inline-item']" v-if="buttonList.indexOf('chainSelect') > -1">
        <b-form-select v-model="chainSelected" :options="chainList" @change="selectedHotelChain"></b-form-select>
      </li>
    </ul>
    <ul class="list-inline propFilter-top" v-else-if="filterType === 'lesiurTab'">
      <li :class="['list-inline-item', priceLTH ? 'active':'']" v-if="buttonList.indexOf('priceLTH') > -1">
        <button
          type="checkbox"
          class="custom-control-input"
          id="priceLTH"
          @click="setKindSort('priceLTH')"
        />
        <label class="lesiurTab" for="priceLTH">
          <span>{{ $t('search-result.priceLTH') }}</span>
        </label>
      </li>
      <li :class="['list-inline-item', priceHTL ? 'active':'']" v-if="buttonList.indexOf('priceHTL') > -1">
        <button
          type="checkbox"
          class="custom-control-input"
          id="priceHTL"
          @click="setKindSort('priceHTL')"
        />
        <label class="lesiurTab" for="priceHTL">
          <span>{{ $t('search-result.priceHTL') }}</span>
        </label>
      </li>
    </ul>
  </div>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue';

export default {
  name: 'FilterButtons',
  components: {
    BFormSelect,
  },
  props: {
    buttonList: {
      type: Array,
      defult: () => [],
    },
    filterType: {
      type: String,
      default: '',
    },
    chainList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      all: true,
      priceHTL: false,
      priceLTH: false,
      gradeLTH: false,
      gradeHTL: false,
      duration: false,
      review: false,
      stars: false,
      homeApartment: false,
      topPick: false,
      hotelName: '',
      chainSelected: 'all',
    };
  },
  methods: {
    setKindSort(pKind) {
      this.all = false;
      this.priceHTL = false;
      this.priceLTH = false;
      this[pKind] = true;
      let kind = '', value = false;
      if (pKind.indexOf('price') > -1) {
        kind = 'price';
        value = pKind === 'priceLTH';
      }
      this.$emit('updateSort', [kind, value]);
    },
    selectedHotelChain() {
      this.$emit('updateSort', ['chain', this.chainSelected]);
    },
  },
};
</script>
<style scoped>
.list-inline-item:not(:last-child) {
  margin-left: 0.5rem;
  margin-right: 0;
}
.list-inline-item.active > label span {
  background-color: rgb(0, 97, 171);
  color: #fff;
}
.sort-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.33em;
  color: #313131;
  text-transform: capitalize;
  padding-right: 0;
  padding-left: 0;
  margin-bottom: 0;
}
.list-inline {
  margin-bottom: 0px !important;
}
label.lesiurTab {
  margin-bottom: 0px !important;
}
</style>
