import { render, staticRenderFns } from "./SortButtons.vue?vue&type=template&id=95c2e65a&scoped=true"
import script from "./SortButtons.vue?vue&type=script&lang=js"
export * from "./SortButtons.vue?vue&type=script&lang=js"
import style0 from "./SortButtons.vue?vue&type=style&index=0&id=95c2e65a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95c2e65a",
  null
  
)

export default component.exports